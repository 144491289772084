.personal-data-wrapper {
    display: flex;
    /*flex-direction: column;*/
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #eff3fa;
    gap: 20px;
}

.top-up-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #eff3fa;
    gap: 20px;
}


.personal-data-address-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    gap: 20px;
    height: fit-content;
    width: fit-content;
    max-width: 50%;
}


.personal-data-forwarder-download {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    gap: 20px;
    height: fit-content;
    width: fit-content;
    max-width: 50%;
}


.personal-data-apk-logo-n-text-container {
    display: flex;
    gap: 20px;
}

.personal-data-qr-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: fit-content;
}


.personal-data-title {
    font-family: Roboto;
    margin: 0;
}

.qr-code-wallet-wrapper {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}


.top-up-user-select {
    margin-right: auto;
}

.top-up-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.top-up-input {
    width: 100%;
}

.add-transaction-btn {
    display: flex;
    flex-direction: row;
}


.personal-data-transaction {
    display: flex;
    /*flex-direction: column;*/
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    gap: 20px
}


.transaction-table-wrapper{
    width: 100%;
}

.transaction-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.transaction-table th,
.transaction-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

.transaction-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.even-row {
    background-color: #ffffff;
    height: 50px;
}

.odd-row {
    background-color: #f9f9f9;
}

.red-row {
    background-color: #e66262;
}

.green-row {
    background-color: #88ff88;
}


.orange-row {
    background-color: #f48424;
}

.load-transactions-btn {
    margin-left: auto;
}


.progress-bar-container {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;
    height: 20px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-color: #4caf50; /* Зеленый цвет прогресса */
    text-align: center;
    line-height: 20px;
    color: white;
    border-radius: 5px;
    transition: width 0.3s ease;
}


.profit-topup-btn {
    min-width: 100px;
}
