/* Стили для кнопки меню */
.menu-button {
    position: fixed;
    top: 15px; /* Располагаем кнопку в верхнем левом углу */
    left: 15px;
    background-color: #373a47;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 1001; /* Убедимся, что кнопка над другими элементами */
}


.platform-profit-monitor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 30px;

}

/* Стили для бокового меню */
.sidebar-menu {
    box-sizing: border-box;
    position: fixed;
    padding-top: 30px;
    top: 55px; /* Располагаем меню под хедером */
    left: 0;
    height: calc(100vh - 55px); /* Высота на весь экран минус высота хедера */
    width: 250px;
    background-color: #373a47;
    overflow-y: auto; /* Добавляем прокрутку для длинного меню */
    transition: transform 0.3s ease;
    transform: translateX(-100%); /* Начально скрываем меню за экраном */
    z-index: 1000; /* Убедимся, что меню поверх других элементов */
}

.sidebar-menu::-webkit-scrollbar {
    display: none; /* Убирает скроллбар в WebKit-браузерах (Chrome, Safari) */
}

/* Стили для открытого меню */
.sidebar-menu.open {
    transform: translateX(0); /* Перемещаем меню в видимую область */
}

/* Стили для закрытого меню */
.sidebar-menu.closed {
    transform: translateX(-100%); /* Меню скрыто за экраном */
}

/* Стили для элементов меню */
.menu-item {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-family: Roboto;
}

.menu-item.adminka{
 
}

.menu-item:hover, .submenu-item:hover {
    background-color: #444;
}

.submenu {
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    height: 0;
    padding-left: 0px;
}

.submenu.open {
    height: auto; /* Используем auto для корректного вычисления высоты */
}

.submenu-item {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-family: Roboto;
    font-size: 14px; /* Уменьшаем размер шрифта */
    padding-left: 40px; /* Добавляем отступ слева для вложенных элементов */

}

/* Стили для оверлея */
.overlay {
    position: fixed;
    top: 48px; /* Выравниваем оверлей с нижним краем хедера */
    left: 0;
    width: 100vw; /* Ширина на весь экран */
    height: calc(100vh - 48px); /* Высота на весь экран минус высота хедера */
    background: rgba(0, 0, 0, 0.3);
    z-index: 999; /* Убедимся, что оверлей под меню, но над контентом */
}


