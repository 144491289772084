.card-creator-wrapper, .card-viewer-wrapper, .card-group-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #eff3fa;
    gap: 20px;
}

.card-creator-selector {
    /*height: 20px;*/
    width: 60.5%;
}

.card-creator-input {
    height: 25px;
    width: 60%;
    border-radius: 5px;
    border: 1px black solid;
    font-size: 1rem;
    font-family: Roboto;
}

.create-card-btn-container {
    width: 60.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-creator-btn-wrapper {
    display: flex;
    gap: 10px;
    margin-left: auto;
}

.create-card-btn {
    min-width: 100px;
    margin-left: auto;
    /*align-self: flex-end;*/
}

.create-card-error-message {
    margin: 0;
}


.create-card-success-message {
    margin: 0;
    color: green;

}

.card-status-switch-btn {
    display: flex;
    gap: 5px;
    align-items: center;

}


.fixed-table-width {
    width: 150px;
}

.qr-token-btn {
    margin-right: 10px;
    margin-bottom: 10px;
    user-select: none;
}

.limit-bar {
    background-color: #eee; /* Светлый фон для шкалы */
    border-radius: 8px;
    height: 15px;
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;
}

.limit-fill {
    height: 100%;
    width: 0; /* Изначально ширина будет равна 0, и будет увеличиваться */
    background-color: #ccc; /* Это будет изменяться в зависимости от percent */
    border-radius: 8px;
    transition: width 0.3s ease, background-color 0.3s ease;
}

.limit-text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; /* Позиционирование текста относительно бара */
    top: 50%; /* Центрирование по вертикали */
    left: 50%; /* Центрирование по горизонтали */
    transform: translate(-50%, -50%); /* Для точного центрирования */
    color: #3e3d3d; /* Белый текст */
    font-weight: bold;
    font-size: 12px;
    width: 100%;

}

.percent-text {
    text-align: center; /* Центрирование текста под шкалой */
    font-size: 14px;
    margin-top: 5px;
    font-weight: bold;
    color: #555; /* Нежный серый цвет */
}

.card-info-mini {
    font-size: 12px;
}


.card-creator-qr-link-container-wrapper {
    width: 60.5%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card-creator-qr-link-container {
    display: flex;
    align-items: center;
    gap: 20px
}


.group-create-btn {
    align-self: flex-end;
}