.device-viewer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #eff3fa;
    gap: 20px;
}


.red-bold {
    font-weight: bold;
    color: #bb2222;
}

.green-bold {
    font-weight: bold;
    color: #44dd44;
}


.device-btn {

}