.settings-wrapper {
    display: flex;
    /*flex-direction: column;*/
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 50px 20px 20px 50px;
    gap: 15px;
    width: 100%;
}


.settings-usdt-wrapper,
.settings-banks-wrapper,
.settings-stop-crane-wrapper,
.settings-limits-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #c6c2c2;
    border-radius: 8px;
    padding: 20px;
    width: 45%;
    min-width: 500px;
}

.settings-usdt-header,
.settings-banks-header,
.settings-stop-crane-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;

}


.limit-wrapper{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}


.settings-stop-crane-setter {
    display: flex;
    align-items: center;
}

.circle-container {
    border-radius: 8px;
    padding: 15px;
    background-color: #8ac6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px; /* Отступ между кругом и текстом */
}

.red-circle {
    width: 75px; /* Размер круга */
    height: 75px;
    background-color: red; /* Красный цвет */
    border-radius: 50%; /* Круглая форма */
    border: 2px solid black; /* Черная обводка */
}

.green-circle {
    width: 75px; /* Размер круга */
    height: 75px;
    background-color: #0fe40f; /* Красный цвет */
    border-radius: 50%; /* Круглая форма */
    border: 2px solid black; /* Черная обводка */
}


.settings-stop-crane-indicator-container{
    border-radius: 8px;
    padding: 15px;
    background-color: #8ac6e6;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
}


.stop-crane-indicator{
    margin: 0;
}

.stop-button {
    width: 150px;
    height: 50px;
}





.settings-label {
    background-color: white;
    padding: 5px;
    border-radius: 8px;
    width: fit-content;
    margin: 0;
}

.settings-usdt-setter{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    background-color: #c6c2c2;
    border-radius: 8px;

}

.settings-usdt-price-monitor,
.settings-current-value-monitor{
    border-radius: 8px;
    padding: 15px;
    background-color: #8ac6e6;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: fit-content;
    gap: 10px;
    flex-direction: column;
}


.settings-usdt-value {
    margin: 0;
}

.settings-error-message-wrapper {
    height: fit-content;
}

.settings-error-message {
    margin: 0;
}


/*.settings-banks-wrapper{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    background-color: #c6c2c2;*/
/*    gap: 20px;*/
/*    border-radius: 8px;*/
/*    padding: 20px;*/
/*    width: 40%;*/
/*    min-width: 500px;*/
/*    */
/*    */
/*}*/


.settings-bank-adder{
    display: flex;
    border-radius: 8px;
    padding: 15px;
    background-color: #8ac6e6;
    gap: 10px;
}