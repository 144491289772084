.stats-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #eff3fa;
    gap: 20px;
}


