


.main-admin-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 50px 20px 20px 50px;
    gap: 15px;
    width: 100%;
}


.wrong-accounted-orders-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    background-color: #c6c2c2;
    border-radius: 8px;
    padding: 20px;
    width: 45%;
    min-width: 500px;
}

.admin-div-flex-column-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.admin-div-flex-row-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}


.main-admin-settings-label {
    background-color: white;
    padding: 5px;
    border-radius: 8px;
    width: fit-content;
    margin: 0;
}

.main-admin-data-h {
    margin: 0;
}