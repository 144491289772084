@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&family=Roboto:wght@300;400;500;700&family=Ubuntu:wght@300;400;500;700&display=swap');

@font-face {
    font-family: 'Batman';
    src: url('../fonts/batmanforeveralternatecyr.ttf') format('truetype'),
    url('../fonts/batmanforeveralternatecyr.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
}

#root {
    height: 100%;
}


.margin-0 {
    margin: 0
}

.App {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main {
    display: flex;
    margin-top: 40px; /* Высота хедера, чтобы не перекрывал его */
}


.header {
    background-color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between; /* Размещаем элементы по краям */
    align-items: center;
    position: fixed; /* Фиксируем хедер к верхней части экрана */
    top: 0; /* Располагаем его у верхнего края */
    left: 0; /* Располагаем его у левого края */
    width: 100%; /* Хедер занимает всю ширину экрана */
    z-index: 1000; /* Убедимся, что хедер поверх других элементов */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Тень под хедером */
}

.header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    /*margin-left: 50px;*/
}

.header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px
    /*margin-left: 50px;*/
}

.header-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px
    /*margin-left: 50px;*/
}

.header-page-title {
    margin: 0;
    font-family: Ubuntu
}

.header-username {
    margin: 0;
    color: black;
    font-size: 20px;
    /*font-weight: bold;*/
    font-family: "Batman";
}

.title {
    margin-left: 20px;
    color: black;
    font-size: 24px;
    font-weight: bold;
    font-family: "Batman";
}


.burger-button {
    /*position: fixed;*/
    top: 15px; /* Располагаем кнопку в верхнем левом углу */
    left: 15px;
    background-color: transparent; /* Сделаем фон прозрачным, чтобы не мешал */
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease; /* Плавный переход для поворота */
    z-index: 1001; /* Убедимся, что кнопка над другими элементами */
}

.burger-button.open {
    transform: rotate(90deg); /* Поворачиваем картинку на 45 градусов */
}


/* Стили для блока main */
.main {
    display: flex;
    flex: 1; /* Занимает доступное пространство */
    transition: margin-left 0.3s ease; /* Плавный переход при изменении ширины */
}

/* Когда меню открыто, блок main сжимается */
.main.menu-open {
    margin-left: 150px; /* Оставляем место для меню */
}

/* Когда меню закрыто, блок main занимает всю ширину */
.main.menu-closed {
    margin-left: 0; /* Полная ширина экрана */
}



.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #eff3fa;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 30px;
    border-radius: 10px;
    background-color: white;
    height: fit-content;
}

.login-page-logo{
    margin: auto;
    width: 100px;
}

.login-logo {

    color: black;
    font-size: 24px;
    font-weight: bold;
    font-family: "Batman";
    transition: color 0.5s ease-in-out; /* плавный переход цвета */
}


.logout-btn {
    margin-right: 30px;
    min-width: 60px;

}


.red-for-blue {
    color: rgb(250, 39, 39);
}


.gradient-light-button {
    background: linear-gradient(to bottom, #e6e2e2, #c6c2c2);
    color: #fff;
    border: none;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.gradient-light-button:hover {
    background: linear-gradient(to bottom, #222, #ccc8c8);
}

.gradient-dark-button {
    background: linear-gradient(to bottom, #444, #222);
    color: #fff;
    border: none;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.gradient-dark-button:hover {
    background: linear-gradient(to bottom, #222, #000);
}


.gradient-red-button.animated::after,
.gradient-orange-button.animated::after,
.gradient-green-button.animated::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 250%;
    height: 100%;
    background: repeating-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.3) 0,
            rgba(255, 255, 255, 0.3) 10px,
            transparent 10px,
            transparent 20px
    );
    /* Можно настроить background-size, если потребуется */
    animation: slide-lines-transform 3s linear infinite;
}

@keyframes slide-lines-transform {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}

.gradient-red-button {
    background: linear-gradient(to bottom, #f88, #d44);
    color: #fff;
    border: none;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.gradient-red-button:hover {
    background: linear-gradient(to bottom, #d44, #b22);
}

.gradient-orange-button {
    background: linear-gradient(to bottom, #ffa500, #ff7f00);
    color: #fff;
    border: none;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.gradient-orange-button:hover {
    background: linear-gradient(to bottom, #ff7f00, #e06600);
}


.gradient-green-button {
    background: linear-gradient(to bottom, #8f8, #4d4);
    color: #fff;
    border: none;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.gradient-green-button:hover {
    background: linear-gradient(to bottom, #4d4, #2b2);
}

.load-stat-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}


.empty-message-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /*padding: 30px;*/
    background-color: #eff3fa;
    gap: 20px;
}


.empty-message-frame {
    box-shadow: 0 0 3px 0;
    border-radius: 7px;
    padding: 10px;
    min-height: 100px;
    width: fit-content;
    background-color: #000000;
    display: flex;
    align-items: center; /* Центрирование по вертикали */
    justify-content: center; /* Центрирование по горизонтали */
}

.empty-message-white-shield {
    padding: 30px;
    border-radius: 5px;
    background-color: #ffffff;
    /* Удаление высоты 100%, так как flexbox берет заботу о высоте */
}

.empty-message-content {
    font-family: Ubuntu;
    font-size: 1.5rem;
}

.component-title {
    font-family: Roboto;
    font-weight: normal;
}

.user-creator-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #eff3fa;
    gap: 20px;
}

.user-creator-selector{
    width: 60%;
}


.ws-logs {
    padding: 30px;
}


.flex-table-cell {
    display: flex;
    gap: 5px
}

.flex-table-cell-column {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.flex-table-cell-column button {
    width: 90px; /* Все кнопки равной ширины */
}


.show-check-btn {
    min-width: 100px;
}