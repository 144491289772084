@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&family=Roboto:wght@300;400;500;700&family=Ubuntu:wght@300;400;500;700&display=swap');



.plate-wrapper {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: center;*/
    background-color: white;
    padding: 10px 15px;
    border-radius: 10px;
    height: fit-content;
    min-width: 200px;
}

.h-reset {
    margin: 0;
}

.plate-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.plate-title {
    margin: 0;
    font-family: Roboto;

}


.plate-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.plate-image {
    width: 50px;
    /*margin: 5px;*/
}

.plate-amount {
    font-family: "Ubuntu";
    color: coral;
}


.chart-wrapper {
    /*transform: translateX(-35px);*/
    margin-top: 0;
}



.green-text {
    color: #22bb22;
}


.red-text {
    color: #ea2121;
}