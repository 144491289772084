.trader-creator-wrapper,
.user-viewer-wrapper,
.merchant-creator-wrapper,
.token-creator-wrapper,
.support-creator-wrapper,
.appeal-viewer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #eff3fa;
    gap: 20px;
}


.pass-gen-btn {
    display: flex;
    white-space: nowrap;
    width: fit-content;
}

.user-creator-input {
    height: 25px;
    width: 60%;
    border-radius: 5px;
    border: 1px black solid;
    font-size: 1rem;
    font-family: Roboto;
}

.password-input {
    width: 100%;
}


.user-creator-password-input-n-generator-container {
    width: 60.4%;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}


.create-user-btn-container {
    width: 60.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.create-user-btn {
    min-width: 100px;
    margin-left: auto;

}

.create-user-error-message {
    margin: 0;
}


.create-user-success-message {
    margin: 0;
    color: green;
}


.password-warning {
    width: 60%;

}


.user-creator-checkbox-container {
    display: flex;

    width: 60.5%;
    gap: 15px
}

.user-creator-input-container {
    display: flex;
    flex-direction: column;
    width: 60.5%;
    gap: 15px
}

.wallet-input-wrapper {
    width: 100%;
}


.wallet-input-wrapper {
    display: flex;
    gap: 15px
}

.allowed-wallet-input {
    width: 100%;
    height: 25px;
    border-radius: 5px;
    border: 1px black solid;
    font-size: 1rem;
    font-family: Roboto;
}



.wallet-input-with-add-button {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px
}

.wallet-input-with-add-button .allowed-wallet-input {
    flex: 1;
    margin-right: 10px;
}

.wallet-input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.user-creator-only-card-container {
    display: flex;
    gap: 10px;
    width: 60.5%;
}


.teamlead-merchants-input-container {

    width: 60.5%;
    gap: 10px
}


.teamlead-creator-inner-selector {
    width: 100%
}


.merchant-entry-wrapper {
    display: flex;
    gap: 10px
}

.add-teamlead-merchant-btn {
    min-width: 100px;
}