@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

.shake {
    animation: shake 0.5s ease-in-out;
}



.rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out; /* Добавляет плавный переход для анимации */
}


.rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out; /* Добавляет плавный переход для анимации */
}