.telegram-broadcast-container {
    display: flex;
    height: calc(100vh - 40px); /* Учитываем высоту хедера */
    width: 100%;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
}

.messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: #e5e5e5;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 10px;
}

.message-bubble {
    position: relative;
    background-color: #78b378; /* Цвет сообщений */
    color: #fff;
    padding: 10px 15px;
    border-radius: 15px;
    max-width: 70%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    word-wrap: break-word;
    transition: transform 0.3s;
}

.message-line {
    margin: 0;
    line-height: 1.4;
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: white;
    color: red;
    font-size: 20px;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
}

.message-bubble:hover .delete-button {
    opacity: 1;
}

.input-container {
    flex: 1;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    background-color: #fff;
    border-left: 1px solid #ddd;
}

.info-text {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.message-input {
    width: 100%;
    resize: none;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1.4;
    box-sizing: border-box;
    overflow-y: auto;
}

.trader-select {
    margin-top: 10px;
    padding: 8px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.send-button {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #5cb85c;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    align-self: flex-end;
    transition: background-color 0.3s;
}

.send-button:hover {
    background-color: #4cae4c;
}

.send-button:active {
    background-color: #45a045;
}
