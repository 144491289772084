/* Стили для контейнера переключателя */
.switch {
    position: relative;
    display: inline-block;
    width: 40px; /* Уменьшаем ширину переключателя */
    height: 24px; /* Уменьшаем высоту переключателя */
}

/* Стили для скрытия стандартного чекбокса */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* Стили для ползунка переключателя */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc; /* Цвет фона, когда переключатель выключен */
    transition: .4s;
    border-radius: 24px; /* Радиус скругления для меньшего переключателя */
}

/* Стили для ползунка переключателя, когда он включен */
.slider:before {
    position: absolute;
    content: "";
    height: 18px; /* Уменьшаем высоту ползунка */
    width: 18px; /* Уменьшаем ширину ползунка */
    border-radius: 50%;
    left: 3px; /* Сдвигаем ползунок влево */
    bottom: 3px; /* Сдвигаем ползунок вниз */
    background-color: white;
    transition: .4s;
}

/* Переключатель включен */
input:checked + .slider {
    background-color: #000; /* Цвет фона, когда переключатель включен */
}

/* Ползунок переключателя включен */
input:checked + .slider:before {
    transform: translateX(16px); /* Перемещаем ползунок вправо */
}
